@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.font-Nanum {
  font-family: 'Nanum Gothic', sans-serif;
  letter-spacing: -0.02rem;
  height: 100%;
  touch-action: pan-y;
}

* {
  box-sizing: border-box;
  touch-action: pan-y;
}

html,
body,
#__next {
  height: 100vh;
  min-height: -webkit-fill-available;
  height: calc(var(--vh, 1vh) * 100);
  padding-bottom: env(safe-area-inset-bottom);
}

.mobileMessageHeightCalc {
  height: calc((var(--vh, 1vh) * 100) - 11.3rem);
}

body {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.headerShadow {
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
select {
  /*ios대응*/
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /*화살표 배경 넣기*/
  background: url('/public/ic_arrow_down.png') no-repeat right 9px center; /*화살표 select박스 오른쪽 중앙 배치,배경 흰색*/
}
select::-ms-expand {
  display: none; /* 화살표 없애기 for IE10, 11*/
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #e2e2e2;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
}

textarea::placeholder,
textarea::-webkit-textarea-placeholder {
  font-size: 1rem;
  letter-spacing: -0.1125rem;
}

/* .phoneVhBug {
  min-height: -webkit-fill-available;
  height: calc(var(--vh, 1vh) * 100);
} */

.textAreaShadow {
  box-shadow: 0 -1px 7px 0px rgba(0, 0, 0, 0.1);
}

.buttonShadow {
  box-shadow: 0 0 8px 2px #33333314;
}

.listShadow {
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.08);
}

.qnaModalShadow {
  box-shadow: 0 -1px 7px 0px rgba(0, 0, 0, 0.1);
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}
.onScrollbar::-webkit-scrollbar {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  height: 8px;
}
.date-picker button:disabled p {
  color: rgba(212, 212, 216, var(--tw-text-opacity));
}
.textarea {
  resize: none;
}
.textarea::placeholder {
  letter-spacing: 0;
  font-size: 0.875rem;
}
span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(0, 0, 0, 0.4);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
.removeHighlight {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.removeHighlight:focus {
  outline: none !important;
}
.removeIOSInputRound {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
.dot-flashing {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #6c5ce7;
  color: #6c5ce7;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -12px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #6c5ce7;
  color: #6c5ce7;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 12px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #6c5ce7;
  color: #6c5ce7;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #6c5ce7;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}
.custom-carousel {
  height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
}
.custom-carousel .carousel {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  max-width: 450px;
  min-width: 300px;
  flex: 1 1;
}

.custom-carousel .carousel .slider-wrapper {
  height: 100%;
}
.custom-carousel .carousel .slider-wrapper ul {
  height: 100%;
}
.custom-carousel .carousel .control-dots {
  position: absolute;
  bottom: 1.5rem;
  background-color: transparent;
}

@media (min-width: 700px) {
  .custom-carousel .carousel .control-dots {
    bottom: 0.5rem;
  }
  .custom-carousel .carousel {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    max-width: 300px;
    min-width: 300px;
    flex: 1 1;
  }
}

.custom-carousel .carousel .control-dots li.selected {
  background-color: #6c5ce7;
}

.custom-carousel .carousel .control-dots .dot {
  box-shadow: 0 0 0;
  background-color: #d9d9d9;
  opacity: 1;
  margin: 0 4px;
}

/* 왼쪽 화살표의 기본 색상 변경 */
.carousel .control-prev.control-arrow:before {
  border-right-color: gray !important;
  border-top-color: white !important;
  border-bottom-color: white !important;
  opacity: 0.5 !important;
}

/* 오른쪽 화살표의 기본 색상 변경 */
.carousel .control-next.control-arrow:before {
  border-left-color: gray !important;
  border-top-color: white !important;
  border-bottom-color: white !important;
  opacity: 0.5 !important;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}
.loader {
  margin: 20px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.3em solid rgba(108, 92, 231, 0.2);
  border-right: 0.3em solid rgba(108, 92, 231, 0.2);
  border-bottom: 0.3em solid rgba(108, 92, 231, 0.2);
  border-left: 0.3em solid #6c5ce7;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.loader-white,
.loader-white:after {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}
.loader-white {
  margin: 20px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.3em solid #b0aebc;
  border-right: 0.3em solid #b0aebc;
  border-bottom: 0.3em solid #b0aebc;
  border-left: 0.3em solid white;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
